import React from "react";
import { useDispatch, useSelector } from "react-redux";
import OnboardingTemplate from "../../../Components/Onboarding/OnboardingTemplate";
import CertQuestionWithUpload from "../../../Components/Onboarding/CertQuestionWithUpload.js";
import { updateBusinessAsync } from "../../../Services/Redux/Thunks/updateBusiness.js";

export default function TrainerCert({ nextAction, previousAction, screenNum = 2, screenTotal = 6 }) {
  const dispatch = useDispatch();
  const business = useSelector((state) => state.business); // Get the current business state
  const token = useSelector((state) => state.auth.token); // Get the auth token

  // Function to save responses and update the business information
  const saveResponses = async () => {
    try {
      // Dispatch the async thunk to update the business
      const updatedBusinessData = business;
      const response = await dispatch(updateBusinessAsync({ updatedBusinessData, token }));
      return response;
    } catch (error) {
      console.error("Failed to update business:", error);
    }
  };

    return (
    <>
      <OnboardingTemplate
        component={<CertQuestionWithUpload  question="Do you have a certification?" />}
        screenNum={screenNum}
        screenTotal={screenTotal}
        nextAction={nextAction}
        previousAction={previousAction}
        title={"Certification"}
        saveResponses={saveResponses} // Function to save responses
      />
    </>
  );
}