import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "../Common/Buttons/Button"; // Custom Button
import { Box, TextField, Typography, InputAdornment, IconButton, Popover } from "@mui/material";
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import { addEmail } from "../../Services/Redux/Slices/User.Slice";
import { saveToken, addAuthRoleID } from "../../Services/Redux/Slices/Auth.Slice";
import { LoginHandler, verifyLogin } from "../../Services/APIHandlers/AuthApi";
import jwtDecode from "jwt-decode";

const LoginForm = ({ onSuccess, mode = "dark" }) => {
  const [error, setError] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const email = useSelector((state) => state.user.email);

  const textStyle = mode === "light" ? { color: "#000" } : { color: "#fff" };

  const submitEmail = async () => {
    if (!validateEmail(email)) {
      setError("Invalid Email Address");
      return;
    }
    //console.log("Submit Email");
    try {
      const response = await LoginHandler(email);
      const data = response.data;
      if (data.error) {
        const loginResponse = await verifyLogin(email);
        const token = loginResponse?.data?.verify_token; 
        const userInfo = jwtDecode(token);

        dispatch(saveToken(token));
        dispatch(addAuthRoleID(userInfo.role_id)); 
        //console.log(userInfo); 
        onSuccess();
        return;
      } else {
        setError(data.error);
        return;
      }
    } catch (err) {
      setError("Login Error. Please Contact Admin.");
      console.error(err);
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleInfoClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box sx={{ maxWidth: "sm", mx: "auto", textAlign: "left" }}>
      <Typography variant="h4" sx={{ marginBottom: "10px", color: textStyle.color }}>
        Login
      </Typography>
      {!error && (
        <Typography sx={{ color: textStyle.color }}>
          Login with just your email address.
        </Typography>
      )}
      {error && <Typography sx={{ color: "red" }}>{error}</Typography>}
      
      <Box sx={{ mt: 2 }}>
        <TextField
          fullWidth
          variant="outlined"
          label="Email"
          error={!!error}
          onChange={(e) => dispatch(addEmail(e.target.value))}
          autoFocus
          InputProps={{
            style: { color: textStyle.color },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-describedby={id} onClick={handleInfoClick}>
                  <PrivacyTipIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {/* Popover with OTP information */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        elevation={1} 
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: mode === "light" ? "yellow" : "yellow", // Customize the background color here
          },
        }}
      >
        <Typography sx={{ p: 2, color: mode === "light" ? "black" : "black" }}>
          We use One-Time Password (OTP) via text message to verify your email during login.
        </Typography>
      </Popover>

      <Box sx={{ mt: 2 }}>
        <Button fill="solid" text="Login" onClick={submitEmail} />
      </Box>
    </Box>
  );
};

export default LoginForm;
