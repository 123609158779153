import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Typography, Grid, Button, useTheme  } from "@mui/material";
import DashWelcome from "../../../Components/Dashboard/DashWelcome";
import DashUpcoming from "../../../Components/Dashboard/DashUpcoming";
import DashWallet from "../../../Components/Dashboard/DashWallet";
import SlideInPanel from "../../../Components/Modals/slideInPanel";
import ImageUploader from "../../../Components/Common/ImageUpload";
import { updateProfilePic } from "../../../Services/APIHandlers/ProfileApi";

import { spacingStyles } from "../../../assets/styles/global";

const TrainerDash = ({ Data }) => {
  const token = useSelector((state) => state.auth.token);
  const [isPanelOpen, setPanelOpen] = useState(false);
  const theme = useTheme();

  const openPanel = () => {
    setPanelOpen(true);
  };

  const closePanel = () => {
    setPanelOpen(false);
  };

  const onLoadProfilePicSuccess = (picture_id) => {
    const response = updateProfilePic(picture_id, token);
    console.log(response);
    window.location.reload();
  };

  useEffect(() => {
    localStorage.setItem("userTypeSet", true);
  }, []);

  return (
    <div style={{ ...spacingStyles.desktopMaxWidth, ...spacingStyles.plr10 }}>
      <Box
        sx={{
          p: 2, // Equivalent to spacingStyles.p10
          mt: 2, // Equivalent to spacingStyles.containerTopDwn
          mb: 4, // Equivalent to spacingStyles.mb20h
        }}
      >
        {/* Welcome Section */}
        <DashWelcome openImageUploader={openPanel} />

        {/* Upcoming Bookings Section */}
        <Box sx={{ pt: 2, mb: 2 }}>
          <Typography variant="h5" color="primary" align="left">
            Upcoming Bookings
          </Typography>
          <Typography variant="h6" align="left" color={theme.palette.text.secondary}>
            This Week
          </Typography>
        </Box>
        <DashUpcoming />

        {/* Wallet Section */}
        <Box sx={{ pt: 2, mb: 2 }}>
          <Typography variant="h5" color="primary" align="left">
            Wallet
          </Typography>
        </Box>
        <DashWallet />

        {/* Slide In Panel for Image Upload */}
        <SlideInPanel
          isOpen={isPanelOpen}
          closePanel={closePanel}
          content={ImageUploader}
          onSuccess={onLoadProfilePicSuccess}
        />
      </Box>
    </div>
  );
};

export default TrainerDash;
