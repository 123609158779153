import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateBlackOutDates } from "../../APIHandlers/ScheduleApi";

export const updateBlackOutDatesAsync = createAsyncThunk(
  "blackoutDates/updateBlackOutDates",
  async ({ data, token }, { dispatch, getState, rejectWithValue }) => {
    try {
      console.log("data: ", data);
      const response = await updateBlackOutDates(data, token);
      console.log("response: ", response);
      // Dispatch success message if needed
      // dispatch(showSuccessMessage("Blackout dates updated successfully"));
      return response.data;
    } catch (error) {
      console.log(error);
      // Dispatch error message if needed
      // dispatch(showErrorMessage("Failed to update blackout dates"));
      return rejectWithValue(error.response.data);
    }
  }
);
