import React from 'react';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import PaidIcon from '@mui/icons-material/Paid';
import { styled } from '@mui/system';
import { useLocation, useNavigate } from 'react-router-dom';

const FooterMenu = ({ menuClick }) => {
  const [value, setValue] = React.useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  // Set the active tab based on current location
  React.useEffect(() => {
    switch (location.pathname) {
      case '/':
        setValue(0);
        break;
      case '/search':
        setValue(1);
        break;
      case '/bookings':
        setValue(3);
        break;
      case '/wallet':
        setValue(4);
        break;
      default:
        setValue(0);
    }
  }, [location.pathname]);

  return (
    <StyledFooter>
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
      >
        <BottomNavigationAction
          label="Home"
          icon={<HomeIcon />}
          onClick={() => navigate('/Dashboard')}
        />
        <BottomNavigationAction
          label="Explore"
          icon={<SearchIcon />}
          onClick={() => navigate('/search')}
        />
        <FaviconContainer onClick={menuClick}>
          <img
            src="/favicon.ico"
            alt="Favicon"
            style={{ width: '50px', height: '50px', cursor: 'pointer' }}
          />
        </FaviconContainer>
        <BottomNavigationAction
          label="Bookings"
          icon={<EventAvailableIcon />}
          onClick={() => navigate('/bookings')}
        />
        <BottomNavigationAction
          label="Earnings"
          icon={<PaidIcon />}
          onClick={() => navigate('/wallet')}
        />
      </BottomNavigation>
    </StyledFooter>
  );
};

// Styled Footer with CSS grid layout
const StyledFooter = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: theme.palette.background.paper,
  zIndex: 1000,
  display: 'none', // Hidden by default on non-mobile devices
  '@media (max-width: 640px)': {
    display: 'grid',
  },
}));

// Favicon styling for center alignment
const FaviconContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export default FooterMenu;
