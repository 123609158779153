import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { IoCaretBack, IoCaretForward } from "react-icons/io5";
import { useTheme, Box, Typography, IconButton } from "@mui/material";
import gradialGreen from "../../assets/radial_green.png";

const dayNames = ["Su", "M", "Tu", "W", "Th", "F", "Sa"];

const BookingsWeekCalendar = () => {
  const theme = useTheme();
  const [currentDate, setCurrentDate] = useState(moment());
  const [weekDays, setWeekDays] = useState([]);

  const generateWeekDays = (startOfWeek) => {
    const endOfWeek = startOfWeek.clone().endOf("week");
    let days = [];
    let day = startOfWeek;

    // Generate array of weekdays
    while (day <= endOfWeek) {
      days.push(day.clone());
      day = day.clone().add(1, "d");
    }

    setWeekDays(days);
  };

  useEffect(() => {
    generateWeekDays(moment().startOf("week"));
  }, []);

  const nextWeek = () => {
    const nextWeekStart = weekDays[0].clone().add(1, "week").startOf("week");
    generateWeekDays(nextWeekStart);
  };

  const prevWeek = () => {
    const prevWeekStart = weekDays[0]
      .clone()
      .subtract(1, "week")
      .startOf("week");
    generateWeekDays(prevWeekStart);
  };

  const currentMonthName = useMemo(() => {
    if (!weekDays?.length) return "";

    const currentDateInWeek =
      currentDate.isSameOrAfter(weekDays[0], "day") &&
      currentDate.isSameOrBefore(weekDays[weekDays.length - 1], "day");

    return (
      (currentDateInWeek ? currentDate : weekDays?.[0])
        ?.format("MMM YYYY")
        .toUpperCase() || ""
    );
  }, [weekDays, currentDate]);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.paper,
        borderRadius: 1,
        p: 2,
        m: "auto",
        mb: 2,
        boxShadow: theme.shadows[1],
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <IconButton onClick={prevWeek}>
          <IoCaretBack color={theme.palette.primary.main} size={30} />
        </IconButton>
        <Typography
          variant="h6"
          sx={{ color: theme.palette.primary.main, fontWeight: 700 }}
        >
          {currentMonthName}
        </Typography>
        <IconButton onClick={nextWeek}>
          <IoCaretForward color={theme.palette.primary.main} size={30} />
        </IconButton>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: 2,
        }}
      >
        {dayNames.map((day) => (
          <Typography
            key={day}
            sx={{ fontWeight: 600, color: theme.palette.text.primary }}
          >
            {day}
          </Typography>
        ))}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: 2,
        }}
      >
        {weekDays.map((day) => {
          const isSelected = moment(day).isSame(currentDate, "day");

          return (
            <Box
              key={day}
              onClick={() => setCurrentDate(day)}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 35,
                height: 35,
                borderRadius: "50%",
                backgroundColor: isSelected
                  ? theme.palette.success.light
                  : theme.palette.grey[500],
                position: "relative",
                cursor: "pointer",
              }}
            >
              <img
                src={gradialGreen}
                style={{
                  position: "absolute",
                  left: -5,
                  top: -5,
                  width: 45,
                  height: 45,
                  opacity: isSelected ? 1 : 0,
                  zIndex: -1,
                }}
                alt="Gradient"
              />
              <Typography
                sx={{ color: theme.palette.text.primary, fontWeight: 600 }}
              >
                {day.format("D")}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default BookingsWeekCalendar;
