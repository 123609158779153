import React, { useState } from "react";
import { BsEnvelope, BsThreeDotsVertical } from "react-icons/bs";
import { FaCircle } from "react-icons/fa";
import { Menu, MenuItem, IconButton, Box, Typography, Avatar, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import calendarReschedule from "../../assets/calendar-reschedule.png";

const PendingCard = ({ item: { id, time, gym, location, image, trainer, checkedIn } }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const menuVisible = Boolean(anchorEl);
  const theme = useTheme();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        borderRadius: 2,
        p: 2,
        boxShadow: theme.shadows[1],
      }}
    >
      {/* First Row: Time and Menu */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="body1" color={theme.palette.text.primary} fontWeight={600}>
          {time}
        </Typography>

        <Box display="flex" alignItems="center">
          <Box
            
            display="flex"
            alignItems="center"
            gap={1}
            sx={{
              backgroundColor: theme.palette.background.default,
              borderRadius: 1,
              p: "2px 5px",
            }}
          >
            <FaCircle color={theme.palette.success.main} size={13} />
            <Typography
              variant="body2"
              fontWeight={500}
              color={theme.palette.text.primary}
            >
              3 Hrs Remaining
            </Typography>
          </Box>

          <IconButton onClick={handleMenuOpen}>
            <BsThreeDotsVertical
              color={theme.palette.text.primary}
              size={25}
              style={{ marginLeft: 5 }}
            />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={menuVisible}
            onClose={handleMenuClose}
            PaperProps={{
              sx: { borderRadius: 2, mt: 1 },
            }}
          >
            <MenuItem onClick={handleMenuClose}>
              <img
                src={calendarReschedule}
                style={{ width: 18, height: 18, marginRight: 10 }}
                alt="Calendar Reschedule"
              />
              Reschedule
            </MenuItem>
            <MenuItem onClick={handleMenuClose}>
              <BsEnvelope
                size={18}
                color={theme.palette.text.primary}
                style={{ marginRight: 10 }}
              />
              Message
            </MenuItem>
          </Menu>
        </Box>
      </Box>

      {/* Gym and Location */}
      <Typography variant="h6" sx={{ mt: 2 }}>
        {gym}
      </Typography>
      <Typography variant="body2" color={theme.palette.text.secondary}>
        {location}
      </Typography>

      {/* Bottom Section: Trainer and Actions */}
      <Box display="flex" justifyContent="space-between" alignItems="flex-end" mt={2}>
        <Box>
          <Avatar
            src={image}
            sx={{ width: 45, height: 45, borderRadius: "50%" }}
            alt="Trainer"
          />
          <Typography variant="body2" fontWeight={500} mt={1}>
            {trainer}
          </Typography>
        </Box>

        <Box display="flex" gap={2}>
          <Button
            variant="contained"
            disabled={checkedIn}
            sx={{
              backgroundImage: theme.palette.gradient.confirm,
              color: theme.palette.common.black,
              fontWeight: 500,
              // Apply styles when the button is disabled
              "&.Mui-disabled": {
                //backgroundColor: theme.palette.grey[400], // Custom background for disabled state
                color: theme.palette.text.disabled, // Custom text color for disabled state
              },
            }}

          >
            Accept
          </Button>

          <Button
            variant="outlined"
            //disabled={checkedIn}
            sx={{
              //borderColor: theme.palette.grey[400],
              backgroundColor: theme.palette.error.main,
              color: theme.palette.text.white,
              fontWeight: 500,
              "&.Mui-disabled": {
                backgroundColor: theme.palette.error.main, // Custom background for disabled state
                color: theme.palette.text.disabled, // Custom text color for disabled state
                borderColor: theme.palette.error.main,
              },
            }}
          >
            Decline
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PendingCard;
