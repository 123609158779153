import React from "react";
import { useDispatch, useSelector } from "react-redux";

import OnboardingTemplate from "../../../Components/Onboarding/OnboardingTemplate";
//import OperationTime from "../../../Components/Calendar/OperationTime";
import OperationTimeGrid from "../../../Components/Calendar/OperationTimeGrid";

import { updateScheduleAsync } from "../../../Services/Redux/Thunks/updateSchedule";
import { selectScheduleState } from "../../../Services/Redux/Slices/Schedule.Slice";

export default function ScheduleSetup({ nextAction, previousAction, screenNum = 1, screenTotal = 1 }) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);

  const scheduleState = useSelector(selectScheduleState);

  const saveResponses = async () => {
    try {
      const response = await dispatch(updateScheduleAsync({ data: scheduleState, token }));
      if (response?.payload) { // Check if response and payload exist
        return response.payload;
      } else {
        throw new Error("No data returned from saveResponses");
      }
    } catch (error) {
      console.error("Failed to save responses:", error);
      return null; // Return null or handle the error as needed
    }
  };

  return (
    <> 
      <OnboardingTemplate
        component={<OperationTimeGrid />}
        screenNum={screenNum}
        screenTotal={screenTotal}
        nextAction={nextAction}
        previousAction={previousAction}
        title={"Availabiity"}
        saveResponses = {saveResponses}
      />
    </>
  );
}