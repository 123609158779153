import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MdCalendarMonth } from "react-icons/md";

import { Box, Typography, Grid, Tabs as MuiTabs, Tab } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { colors, globalStyles, spacingStyles } from "../../../assets/styles/global";
import ConfirmedCard from "../../../Components/Bookings/ConfirmedCard";
import PendingCard from "../../../Components/Bookings/PendingCard";
import CanceledCard from "../../../Components/Bookings/CanceledCard.js";
import { fetchSchedule } from "../../../Services/APIHandlers/ScheduleApi";
import BookingsWeekCalendar from "../../../Components/Bookings/BookingsWeekCalendar";
import Loader from "../../../Components/Common/Loader";

const tabNames = ["CONFIRMED", "PENDING", "CANCELED"];

const dummyConfirmed = [
  {
    id: 0,
    time: "8:00am-8:45am",
    gym: "Florida Kickboxing",
    location: "Riviera Beach, FL",
    image: "https://picsum.photos/200",
    trainer: "Rodrigo H",
    checkedIn: true,
  },
  {
    id: 1,
    time: "10:00am-10:45am",
    gym: "Florida Kickboxing",
    location: "Riviera Beach, FL",
    image: "https://picsum.photos/200",
    trainer: "Luis S",
    checkedIn: false,
  },
  {
    id: 2,
    time: "1:00pm-1:45am",
    gym: "Fight Club of Palm Beach",
    location: "West Palm Beach, FL",
    image: "https://picsum.photos/200",
    trainer: "Luis S",
    checkedIn: false,
  },
];

const dummyPending = [
  {
    id: 0,
    time: "8:00am-8:45am",
    gym: "Florida Kickboxing",
    location: "Riviera Beach, FL",
    image: "https://picsum.photos/200",
    trainer: "Rodrigo H",
    checkedIn: true,
  },
  {
    id: 1,
    time: "10:00am-10:45am",
    gym: "Florida Kickboxing",
    location: "Riviera Beach, FL",
    image: "https://picsum.photos/200",
    trainer: "Luis S",
    checkedIn: false,
  },
  {
    id: 2,
    time: "10:00am-10:45am",
    gym: "Florida Kickboxing",
    location: "Riviera Beach, FL",
    image: "https://picsum.photos/200",
    trainer: "Luis S",
    checkedIn: false,
  },
  {
    id: 3,
    time: "10:00am-10:45am",
    gym: "Florida Kickboxing",
    location: "Riviera Beach, FL",
    image: "https://picsum.photos/200",
    trainer: "Luis S",
    checkedIn: false,
  },
  {
    id: 4,
    time: "10:00am-10:45am",
    gym: "Florida Kickboxing",
    location: "Riviera Beach, FL",
    image: "https://picsum.photos/200",
    trainer: "Luis S",
    checkedIn: false,
  },
];

const dummyCanceled = [
  {
    id: 0,
    time: "8:00am-8:45am",
    gym: "Fight club of Palm Beach",
    location: "West Palm Beach, FL",
    image: "https://picsum.photos/200",
    trainer: "Rodrigo H",
    checkedIn: true,
  },
  {
    id: 1,
    time: "8:00am-8:45am",
    gym: "Fight club of Palm Beach",
    location: "West Palm Beach, FL",
    image: "https://picsum.photos/200",
    trainer: "Rodrigo H",
    checkedIn: true,
  },
  {
    id: 3,
    time: "8:00am-8:45am",
    gym: "Fight club of Palm Beach",
    location: "West Palm Beach, FL",
    image: "https://picsum.photos/200",
    trainer: "Rodrigo H",
    checkedIn: true,
  },
];


const Bookings = () => {
  const theme = useTheme();
  const token = useSelector((state) => state.auth.token);

  const [isLoading, setLoading] = useState(false);
  const [bookedMe, setBookedMe] = useState([]);

  const [activeTab, setActiveTab] = useState(tabNames[0]);



  useEffect(() => {
    const getSchedule = async () => {
      try {
        setLoading(true);
        const { data } = await fetchSchedule(token);
        //console.log("..data", JSON.stringify(data));
        setBookedMe(data.bookedMe);
      } catch (error) {
        console.log(">>>Error in getSchedule", error);
      } finally {
        setLoading(false);
      }
    };

    getSchedule();
  }, [token]);

  const Tabs = () => {
    const theme = useTheme();
  
    const handleTabChange = (event, newValue) => {
      setActiveTab(newValue);
    };

  return (
    <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
      <MuiTabs
        value={activeTab}
        onChange={handleTabChange}
        centered
        textColor="primary"
        indicatorColor="primary"
      >
        {tabNames.map((tabName) => (
          <Tab key={tabName} label={tabName} value={tabName} />
        ))}
      </MuiTabs>
    </Box>
  );

  };

  const EmptyComponent = () =>
    isLoading ? (
      <Loader />
    ) : (
      <div
        style={{
          gap: 10,
          marginTop: 50,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            ...globalStyles.h1,
            maxWidth: "70%",
            textAlign: "center",
            color: colors.dark_gray.color,
          }}
        >
          You don't have any sessions booked, yet.
        </div>
        <MdCalendarMonth size={40} color={colors.dark_gray.color} />
      </div>
    );

  return (
    <div style={{...spacingStyles.desktopMaxWidth}}
    >
      <Box sx={{  padding: "20px 0 40px" }}>
        <Typography sx={{ maxWidth: "100vw", paddingBottom: "40px" }} variant="h4" color={theme.palette.text.primary}>
          Bookings
        </Typography>


        <BookingsWeekCalendar />
        <Tabs />

        <div style={{ marginTop: 12, paddingBottom: 40 }}>
          {activeTab === "CONFIRMED" && (
            <Box>
              <Grid container columnSpacing={5} rowSpacing={2} width="100%">
                {bookedMe?.map((item) => (
                  <Grid item md={6} width="100%" >
                    <ConfirmedCard item={item} />
                  </Grid>
                ))}
              </Grid>
              {!bookedMe?.length && !isLoading && <EmptyComponent />}
            </Box>
          )}

          {activeTab === "PENDING" && (
            <Box>
              <Grid container columnSpacing={5} rowSpacing={2} width="100%">
                {dummyPending?.map((item) => (
                  <Grid item md={6} width="100%" >
                    <PendingCard item={item} />
                  </Grid>
                ))}
              </Grid>
              {!dummyPending?.length && !isLoading && <EmptyComponent />}
            </Box>
          )}

          {activeTab === "CANCELED" && (
            <Box>
              <Grid container columnSpacing={5} rowSpacing={2} width="100%">
                {dummyCanceled?.map((item) => (
                  <Grid item md={6} width="100%">
                    <CanceledCard item={item} />
                  </Grid>
                ))}
              </Grid>
              {!dummyCanceled?.length && !isLoading && <EmptyComponent />}
            </Box>
          )}
        </div>

      </Box>
    </div>
  );
};

export default Bookings;

