import React, { useEffect } from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import { Button } from "../../../Components/Common/Buttons/Button";
import { Container, Typography, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";  // Import MUI theme hook
import { useSelector } from "react-redux";

const SetupComplete = ({ nextAction }) => {
  const token = useSelector((state) => state.auth.token);
  const theme = useTheme();  // Access the current theme (dark or light mode)

  const handleSubmit = async () => {
    nextAction();
    // try {
    //   const response = await updateSpaceDetails(
    //     JSON.stringify(location),
    //     token
    //   );
    //   if (response.data.success) {
    //     nextAction();
    //   } else {
    //     console.log(response.data);
    //   }
    // } catch (err) {
    //   getErrorMessage(err);
    // }
  };

  useEffect(() => {
    localStorage.setItem("userTypeSet", true);
  }, []);

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          padding: theme.spacing(2),
        }}
      >
        <Box>
          <AiFillCheckCircle size={140} color="#7ceb9a" />
        </Box>

        {/* <Typography
          variant="h4"
          sx={{color: theme.palette.text.primary , fontWeight: 400, textAlign: "center", mt: 2 }}
        >
          HopperSite{" "}
        </Typography> */}

        <Typography
          variant="h4"
          sx={{ fontWeight: 700, textAlign: "center", mt: 0.5, color: theme.palette.text.primary }}
        >
          Setup complete!
        </Typography>

        <Typography
          variant="body1"
          sx={{
            color: theme.palette.text.primary ,
            textAlign: "center",
            fontWeight: 500,
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(8),
            whiteSpace: "pre-line",  // For handling newlines in text
          }}
        >
          All that's left is to complete your profile info.{`\n`}
          We will review your info and contact you to activate your account.
        </Typography>

        <Button
          fill= "solid"
          text="Let's Go!!!"
          onClick={handleSubmit}
        />

      </Box>
    </Container>
  );
};

export default SetupComplete;
