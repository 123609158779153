import React, { useState, useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import AuthContext from "./AuthWrapper";
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import HomeIcon from '@mui/icons-material/Home';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import PaidIcon from '@mui/icons-material/Paid';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ExpandMore from '@mui/icons-material/ExpandMore';
import OnboardingSideMenu from './OnboardingSideMenu'; // Import the OnboardingSideMenu component

// Initialize completion status for onboarding items
const initialCompletionState = {
    Setup: false,
    Description: false,
    Gallery: false,
    Space: false,
    Amenities: false,
    Location: false,
    Schedule: false,
    Special: false,
    SetupComplete: false,
};

const menuSchema = {
    Bookings: { label: "Bookings", icon: <EventAvailableIcon />, collapsible: false },
    Earnings: { label: "Earnings", icon: <PaidIcon />, collapsible: false },
};

const DeskSideMenu = ({ activeScreen, onMenuClick }) => {
    const [isSubMenuOpen, setSubMenuOpen] = useState(false);
    const [completionStatus, setCompletionStatus] = useState(initialCompletionState);
    const { darkMode } = useContext(AuthContext);
    const theme = useTheme();

    const menuTextStyles = {
        primaryTypographyProps: {
            style: { color: theme.palette.text.primary },
        },
    };

    const getMenuClass = (screen) => activeScreen === screen ? 'active-sidemenu-item' : '';

    const toggleSubMenu = () => setSubMenuOpen(!isSubMenuOpen);

    // Function to mark a section as complete
    const markAsComplete = (subMenuKey) => {
        setCompletionStatus((prevState) => ({
            ...prevState,
            [subMenuKey]: true,
        }));
    };

    return (
        <div className="onboard-side-menu">
            <MenuList className="side-menu-list" style={{ width: '100%' }}>
                {/* Iterate through main menu schema */}
                {Object.keys(menuSchema).map((menuKey) => {
                    const menuItem = menuSchema[menuKey];
                    return (
                        <MenuItem
                            key={menuKey}
                            className={`mui-menu-item ${getMenuClass(menuKey)}`}
                            onClick={() => onMenuClick(menuKey)}
                        >
                            <ListItemIcon style={{ minWidth: '40px', color: darkMode ? '#fff' : '#000' }}>
                                {menuItem.icon}
                            </ListItemIcon>
                            <ListItemText primary={menuItem.label} {...menuTextStyles} />
                        </MenuItem>
                    );
                })}

                {/* Onboarding Menu */}
                <MenuItem
                    key="Onboarding"
                    className="mui-menu-item"
                    onClick={toggleSubMenu}
                >
                    <ListItemIcon style={{ minWidth: '40px', color: darkMode ? '#fff' : '#000' }}>
                        <HomeIcon /> {/* You can change this icon as needed */}
                    </ListItemIcon>
                    <ListItemText primary="Onboarding" {...menuTextStyles} />
                    <ListItemIcon style={{ minWidth: '40px', marginLeft: 'auto' }}>
                        {isSubMenuOpen ? <ExpandMore /> : <ChevronLeftIcon />}
                    </ListItemIcon>
                </MenuItem>

                {/* Collapsible Onboarding SubMenu */}
                <Collapse in={isSubMenuOpen} timeout="auto" unmountOnExit>
                    <OnboardingSideMenu
                        activeScreen={activeScreen}
                        onMenuClick={(subKey) => {
                            onMenuClick(subKey);
                            markAsComplete(subKey);  // Mark the subitem as complete
                        }}
                        completionStatus={completionStatus}  // Pass completion status
                    />
                </Collapse>
            </MenuList>
        </div>
    );
};

export default DeskSideMenu;
