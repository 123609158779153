import { createSlice } from "@reduxjs/toolkit";

// Get the data from local storage, if available
let storedToken, storedUser;

const getStoredData = () => {
  try {
    storedToken = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;
  } catch (e) {
    storedToken = null;
  }

  try {
    storedUser = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null;
  } catch (e) {
    storedUser = null;
  }
};

getStoredData();

const initialState = {
  isAuthenticated: !!storedToken,
  token: storedToken || "",
  role_id: storedUser?.role_id || "",
  accountData: null,
};

const AuthSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    clearAuth(state, action) {
      localStorage.clear();
      getStoredData();
      state = initialState;
    },
    saveToken(state, action) {
      state.token = action.payload;
    },
    addAuthRoleID: (state, action) => {
      state.role_id = Number(action.payload);
      console.log('Role ID updated in Redux:', action.payload);
      localStorage.setItem("userTypeSet", true);
      //localStorage.setItem("user", JSON.stringify(state)); // Update local storage
    },
    addAccountData(state, action) {
      //console.log("account", JSON.stringify(action.payload));
      localStorage.setItem("account", JSON.stringify(action.payload));
      state.accountData = action.payload;
    },
    createAuth(state, action) {
      
      localStorage.setItem("token", JSON.stringify(action.payload.token));
      return { ...action.payload, isAuthenticated: true };
    },
    removeAuth(state) {
      localStorage.setItem("token", "");
      getStoredData();
      state = initialState;
    },
  },
});

export const { clearAuth, createAuth, saveToken, removeAuth, addAccountData,addAuthRoleID } =
  AuthSlice.actions;

export default AuthSlice.reducer;
