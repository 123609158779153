import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Stack, TextField, FormControl, InputLabel, MenuItem, Select, useTheme } from "@mui/material";
import { Fab, Zoom } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import { updateBusiness } from "../../Services/APIHandlers/ProfileApi";
import { addBusiness } from "../../Services/Redux/Slices/Business.Slice";
import { Button } from "../Common/Buttons/Button";  // Using the custom Button component


const ProfileBusiness = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const bData = useSelector((state) => state.business);
  const theme = useTheme();

  const [formData, setFormData] = useState({
    name: bData.name || "",
    description: bData.description || "",
    certifications: bData.certifications || "",
    meta: bData.meta || "",
    specialty: bData.specialty || "",
    years_exp: bData.years_exp || "",
    language: bData.language || "",
  });

  const [isChanged, setIsChanged] = useState(false);

  const specialtyOptions = [
    { name: "boxing", display: "Boxing/Kickboxing" },
    { name: "bodybuilding", display: "Bodybuilding" },
    { name: "crossfit", display: "CrossFit" },
    { name: "corrective", display: "Corrective Exercise" },
    { name: "dance", display: "Dance Fitness" },
    { name: "flexibility", display: "Flexibility" },
    { name: "hiit", display: "HIIT" },
    { name: "nutrition", display: "Nutrition and Dieting" },
    { name: "performance", display: "Performance" },
    { name: "pilates", display: "Pilates" },
    { name: "seniors", display: "Senior Fitness" },
    { name: "strength", display: "Strength and Conditioning" },
    { name: "water aerobics", display: "Water Aerobics" },
    { name: "weight loss", display: "Weight Loss Transformation" },
    { name: "yoga", display: "Yoga" },
    { name: "youth", display: "Youth Fitness" },
  ];

  const yearsExp = [
    { name: ">1", display: "Less than 1 year" },
    { name: "1-3", display: "1-3 years" },
    { name: "4-10", display: "4-10 years" },
    { name: "10-20", display: "10-20 years" },
    { name: "20+", display: "20+ years" },
  ];

  const languages = [
    { name: "English", display: "English" },
    { name: "Spanish", display: "Spanish" },
    { name: "French", display: "French" },
    { name: "Portuguese", display: "Portuguese" },
    { name: "German", display: "German" },
    { name: "Vietnamese", display: "Vietnamese" },
    { name: "Italian", display: "Italian" },
    { name: "Arabic", display: "Arabic" },
    { name: "Chinese", display: "Chinese" },
    { name: "Russian", display: "Russian" },
    { name: "Polish", display: "Polish" },
  ];

  const inputBackgroundColor = theme.palette.background.paper;
  const inputTextColor = theme.palette.text.primary;

  // Watch for changes in formData and update isChanged flag
  // useEffect(() => {
  //   const hasChanges = Object.keys(formData).some((key) => formData[key] !== bData[key]);
  //   setIsChanged(hasChanges);
  // }, [formData, bData]);

  const handleInputChange = (field, value) => {

    setFormData({
      ...formData,
      [field]: value,
    });
    setIsChanged(true);
  };

  const handleSave = async () => {
    try {
      await updateBusiness(formData, token);
      dispatch(addBusiness(formData)); // Update the Redux store
      setIsChanged(false);  // Reset change status
    } catch (error) {
      console.error("Error saving business data:", error);
    }
  };

  return (
    <>
      <Stack spacing={5} pt={5} pb={5} sx={{ textAlign: "left", marginBottom: 10 }}>
        {/* Business Name Field */}
        <Stack spacing={2}>
          <TextField
            label="Business Name"
            value={formData.name}
            onChange={(e) => handleInputChange("name", e.target.value)}
            fullWidth
            variant="outlined"
            sx={{ backgroundColor: inputBackgroundColor, borderRadius: 1 }}
          />
        </Stack>

        {/* Description Field */}
        <Stack spacing={2}>
          <TextField
            label="Describe your business..."
            value={formData.description}
            onChange={(e) => handleInputChange("description", e.target.value)}
            fullWidth
            multiline
            rows={3}
            variant="outlined"
            sx={{ backgroundColor: inputBackgroundColor, borderRadius: 1 }}
          />
        </Stack>

        {/* Certifications Field */}
        <Stack spacing={2}>
          <TextField
            label="Your certifications..."
            value={formData.certifications}
            onChange={(e) => handleInputChange("certifications", e.target.value)}
            fullWidth
            multiline
            rows={3}
            variant="outlined"
            helperText="Separate each item with a comma"
            sx={{ backgroundColor: inputBackgroundColor, borderRadius: 1 }}
          />
        </Stack>

        {/* Meta (Keywords) Field */}
        <Stack spacing={2}>
          <TextField
            label="Add keywords..."
            value={formData.meta}
            onChange={(e) => handleInputChange("meta", e.target.value)}
            fullWidth
            multiline
            rows={3}
            variant="outlined"
            helperText="Separate each item with a comma"
            sx={{ backgroundColor: inputBackgroundColor, borderRadius: 1 }}
          />
        </Stack>

        {/* Specialty Field */}
        <FormControl fullWidth>
          <InputLabel>Specialty</InputLabel>
          <Select
            value={formData.specialty}
            onChange={(e) => handleInputChange("specialty", e.target.value)}
            sx={{
              backgroundColor: inputBackgroundColor,
              color: inputTextColor,
              borderRadius: 1,
              '& .MuiSelect-select': {
                color: inputTextColor,
              }
            }}
          >
            {specialtyOptions.map((option) => (
              <MenuItem key={option.name} value={option.name}>
                {option.display}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Years Experience Field */}
        <FormControl fullWidth>
          <InputLabel>Years Experience</InputLabel>
          <Select
            value={formData.years_exp}
            onChange={(e) => handleInputChange("years_exp", e.target.value)}
            sx={{
              backgroundColor: inputBackgroundColor,
              color: inputTextColor,
              borderRadius: 1,
              '& .MuiSelect-select': {
                color: inputTextColor,
              }
            }}
          >
            {yearsExp.map((option) => (
              <MenuItem key={option.name} value={option.name}>
                {option.display}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Languages Field */}
        <FormControl fullWidth>
          <InputLabel>Languages</InputLabel>
          <Select
            value={formData.language}
            onChange={(e) => handleInputChange("language", e.target.value)}
            sx={{
              backgroundColor: inputBackgroundColor,
              color: inputTextColor,
              borderRadius: 1,
              '& .MuiSelect-select': {
                color: inputTextColor,
              }
            }}
          >
            {languages.map((option) => (
              <MenuItem key={option.name} value={option.name}>
                {option.display}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Save Button */}
        {/* <Button
          fill="confirm"
          text="Save"
          onClick={handleSave}
          disabled={!isChanged} // Button is disabled when no changes
        >
          Save Changes
        </Button> */}

              {/* Save Button */}
      <Zoom in={isChanged}>
        <Fab
          color="primary"
          aria-label="save"
          sx={{
            position: 'absolute',
            top: 16,
            right: 16,
          }}
          onClick={handleSave}
        >
          <SaveIcon />
        </Fab>
      </Zoom>
      </Stack>
    </>
  );
};

export default ProfileBusiness;
