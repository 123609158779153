import { configureStore } from "@reduxjs/toolkit";

//slices
import AuthSlice from "./Slices/Auth.Slice";
import ImageSlice from "./Slices/Image.Slice";
import LocationSlice from "./Slices/Location.Slice";
import UserSlice from "./Slices/User.Slice";
import BusinessSlice from "./Slices/Business.Slice";
import SearchSitesSlice from "./Slices/SearchSites.Slice";
import BookingSlice from "./Slices/Booking.Slice";
import BlackoutDatesSlice from "./Slices/BlackoutDates.Slice";
import SelectedGym from "./Slices/SelectedGym.Slice";
import OnboardingSlice from "./Slices/Onboarding.Slice";
import ScheduleSlice from "./Slices/Schedule.Slice";
import WalletSlice from "./Slices/Wallet.Slice";
import MatchMeSlice from "./Slices/MatchMe.Slice";
import ContactSlice from "./Slices/Contact.Slice";

const Store = configureStore({
  reducer: {
    auth: AuthSlice,
    user: UserSlice,
    contact: ContactSlice,
    location: LocationSlice,
    image: ImageSlice,
    business: BusinessSlice,
    sites: SearchSitesSlice,
    booking: BookingSlice,
    selectedGym: SelectedGym,
    onboarding: OnboardingSlice,
    schedule: ScheduleSlice,
    wallet: WalletSlice,
    matchMe: MatchMeSlice,
    blackout: BlackoutDatesSlice,
  },
});

export default Store;
