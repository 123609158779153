import { createSlice } from "@reduxjs/toolkit";
import { updateBusinessAsync } from "../Thunks/updateBusiness";

const initialState = {
  business_id: "",
  name: "",
  description: "",
  meta: "",
  photo: "",
  status: "",
  certified: "",
  certification: "",
  language: "",
  specialty: "",
  user_id: "",
  years_exp: "",
  website: "",
  facebook: "",
  instagram: "",
  youtube: "",
  tiktok: "",
};

const BusinessSlice = createSlice({
  name: "Business",
  initialState,
  reducers: {
    removeBusiness(state) {
      state = initialState;
      localStorage.removeItem("business");
    },
    addBusiness(state, action) {
      // Merge existing state with new data from action payload
      const updatedState = {
        ...state,    // Keep existing state
        ...action.payload, // Merge new data from action.payload
      };
      localStorage.setItem("business", JSON.stringify(updatedState)); // Save updated state to local storage
      return updatedState; 
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateBusinessAsync.pending, (state) => {
        //state.status = 'loading';
      })
      .addCase(updateBusinessAsync.fulfilled, (state, action) => {
        //state.status = 'succeeded';
        //console.log("Action Update", action.payload);
        //console.log("State update: ", state); 
        return action.payload.data;
      })
      .addCase(updateBusinessAsync.rejected, (state, action) => {
        //state.status = 'failed';
        //state.error = action.payload; // Capture and store the error
      });
  },
});

export const { addBusiness, removeBusiness } = BusinessSlice.actions;

export default BusinessSlice.reducer;
