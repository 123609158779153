import React, { useEffect, useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Typography, Grid } from "@mui/material";
import WalletDetails from "../../Components/Wallet/WalletDetails";
import Transfers from "../../Components/Wallet/Transfers";
import { getWalletData, setupTransfer, cashOut } from "../../Services/APIHandlers/WalletApi";
import AuthContext from "../../Navigation/AuthWrapper";
import { addUser } from "../../Services/Redux/Slices/User.Slice";
import { globalStyles, spacingStyles } from "../../assets/styles/global";
import { useTheme } from "@mui/material/styles";
import { Button } from "../../Components/Common/Buttons/Button"; // Using the custom Button
import { unset } from "lodash";

const Wallet = () => {
  const [showTransfers, setShowTransfer] = useState(false);
  const details = useSelector((state) => state.wallet);
  const auth = useContext(AuthContext);
  const dispatch = useDispatch();
  const [userData, setUserData] = useState(null);
  const theme = useTheme();

  const getUserData = async () => {
    try {
      const storedUserData = await localStorage.getItem("user");
      setUserData(JSON.parse(storedUserData));
    } catch (error) {
      console.error("Error retrieving user data:", error);
    }
  };

  useEffect(() => {
    getUserData();

    const fetchData = async () => {
      try {
        const response = await getWalletData(auth.token);
        //console.log(response); 

        //console.log(response.data);
        // if (response.data.success) {
        //   const walletData = response.data; // Access the 'data' property directly
        //   console.log(walletData);
        // } else {
        //   console.log(response.data);
        // }


      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [auth.token]);

  const handleSetupTransferClick = async () => {
    try {
      const response = await setupTransfer(auth.token);
      if (response.data.user) {
        dispatch(addUser(response.data.user));
      }
      if (response.data.connect_url) {
        window.location.href = response.data.connect_url;
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleCashOut = async () => {
    try {
      const response = await cashOut(auth.token);
      console.log(response.data);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box sx={{ ...spacingStyles.desktopMaxWidth, padding: "0 5px" }}>
      <Typography sx={{ maxWidth: "100vw", padding: "20px 0 40px" }} variant="h4" color={theme.palette.text.primary}>
        Wallet
      </Typography>

      <Grid container spacing={3}>
        {/* Left Column */}
        <Grid item xs={12} md={6} >
          <Box sx={{  ...globalStyles.flexCol, ...globalStyles.flexCenter, ...globalStyles.alignCenter  }} mb={2}>
            <Typography variant="h5" color="text.secondary" gutterBottom>
              Current Balance
            </Typography>

            <Box display="flex" alignItems="center">
              <Typography variant="h3" color="success.main">
                $
              </Typography>
              <Typography variant="h2" color="success.main" ml={1}>
                {details?.current_stats?.cash_available || 0}
              </Typography>
            </Box>
            <Typography variant="subtitle1" color="text.secondary">
              USD
            </Typography>
          </Box>

          {/* Buttons */}
          <Box>
            {/* Transfer To Bank Button */}
            <Button
              disabled={
                userData?.stripe_payoutId === null 
                || details?.current_stats?.cash_available === 0
              }
              fill={userData?.stripe_payoutId ? "confirm" : "outline"}
              text="Transfer To Bank"
              onClick={handleCashOut}
              sx={{ marginBottom: '20px' }}
            />

            {/* Setup Transfer Button */}
            {userData?.stripe_payoutId === null && (
              <Button
                fill="solid"
                text="Setup Transfer"
                onClick={handleSetupTransferClick}
                sx={{ mb: 2 }}
              />
            )}

            {/* Update Banking Button */}
            {userData?.stripe_payoutId !== null && (
              <Button
                fill="outline"
                text="Update Banking"
                onClick={handleSetupTransferClick}
              />
            )}
          </Box>
        </Grid>

        {/* Right Column */}
        <Grid item style={{ paddingTop: 'unset'}} xs={12} md={6} >
          <Box display="flex" justifyContent="center" mb={2} gap={1}>
            <Button
              fill={!showTransfers ? "select" : "outline"}
              text="Balance"
              onClick={() => setShowTransfer(false)}
            />
            <Button
              fill={showTransfers ? "select" : "outline"}
              text="Transfers"
              onClick={() => setShowTransfer(true)}
            />
          </Box>

          {/* Details */}
          {showTransfers ? <Transfers /> : <WalletDetails details={details} />}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Wallet;
