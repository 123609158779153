import { useEffect } from "react";
import { useSelector } from "react-redux";

import moment from "moment";

import { globalStyles } from "../../assets/styles/global";

// const mockTransferHistory = [
//     {
//         cash_value: "280.00",
//         bookings: [],
//         refers: "0",
//         noshows: "8",
//         cancelled: "2",
//         cashout_datetime: "2021-09-17 00:00:00",
//         status: "1",
//     },
//     {
//         cash_value: "280.00",
//         bookings: [],
//         refers: "0",
//         noshows: "8",
//         cancelled: "2",
//         cashout_datetime: "2021-09-17 00:00:00",
//         status: "0",
//     },
// ]

const Transfers = () => {
  const details = useSelector((state) => state.wallet);

  useEffect(() => {
    //console.log("Transfers: ",details.payouts);
  }, []);

  return (
    <div style={{ paddingLeft: 20, paddingRight: 20, ...globalStyles.mt50, }}>
      {
        //mockTransferHistory.map((transfer, a) => {
        details.payouts.map((transfer, a) => {
          return (
            <div
              key={a}
              style={{
                ...globalStyles.mb20,
                ...globalStyles.flexRow,
                ...globalStyles.flexAround,
              }}
            >
              <span style={{ ...globalStyles.h1, ...globalStyles.green }}>
                ${transfer.cash_value}
              </span>
              <span style={{ ...globalStyles.h1, ...globalStyles.green }}>
                -
              </span>
              <div>
                <span style={{ ...globalStyles.h1 }}>
                  {transfer.cashout_datetime
                    ? moment(transfer.cashout_datetime).format("MMM. Do YYYY")
                    : ""}
                </span>
                <span
                  style={{
                    ...globalStyles.h2,
                    ...globalStyles.capitalize,
                    ...(transfer.status === "1"
                      ? globalStyles.green
                      : globalStyles.yellow),
                  }}
                >
                  {transfer.status === "1" ? "Confirmed" : "Pending"}
                </span>
              </div>
            </div>
          );
        })
      }
    </div>
  );
};

export default Transfers;
