import React, { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaRegEnvelope } from "react-icons/fa6";
import { Menu, MenuItem, IconButton, Box, Typography, Avatar, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import calendarReschedule from "../../assets/calendar-reschedule.png";

const CanceledCard = ({ item: { id, time, gym, location, image, trainer } }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const menuVisible = Boolean(anchorEl);
  const theme = useTheme();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        borderRadius: 2,
        p: 2,
        boxShadow: theme.shadows[1],
      }}
    >
      {/* First Row: Trainer Image and Details */}
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <Box display="flex" alignItems="center" gap={2}>
          {/* Trainer Image */}
          <Avatar
            src={image}
            sx={{ width: 45, height: 45, borderRadius: "50%" }}
            alt="Trainer"
          />
          {/* Trainer Info */}
          <Box>
            <Typography variant="body1" fontWeight={600} color={theme.palette.text.primary}>
              {trainer}
            </Typography>
            <Typography variant="body2" color={theme.palette.text.secondary}>
              {time}
            </Typography>
          </Box>
        </Box>

        {/* Menu Button */}
        <IconButton onClick={handleMenuOpen}>
          <BsThreeDotsVertical color={theme.palette.text.primary} size={25} />
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          open={menuVisible}
          onClose={handleMenuClose}
          PaperProps={{
            sx: { borderRadius: 2, mt: 1 },
          }}
        >
          <MenuItem onClick={handleMenuClose}>
            <img
              src={calendarReschedule}
              style={{ width: 18, height: 18, marginRight: 10 }}
              alt="Calendar Reschedule"
            />
            Reschedule
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <FaRegEnvelope
              size={18}
              color={theme.palette.text.primary}
              style={{ marginRight: 10 }}
            />
            Message
          </MenuItem>
        </Menu>
      </Box>

      {/* Gym and Location Details */}
      <Box mt={2}>
        <Typography variant="h6" color={theme.palette.text.primary}>
          {gym}
        </Typography>
        <Typography variant="body2" color={theme.palette.text.secondary}>
          {location}
        </Typography>
      </Box>

      {/* Canceled Status */}
      <Box mt={2}>
        <Button
          variant="contained"
          disabled
          sx={{
            backgroundColor: theme.palette.error.main,
            color: theme.palette.common.white,
            textTransform: "none",
            p: "6px 17px",
            fontSize: "0.875rem",
            fontWeight: 500,
            borderRadius: 1,
          }}
        >
          Canceled: 1 Week Ago
        </Button>
      </Box>
    </Box>
  );
};

export default CanceledCard;
