import React, { useEffect, useContext } from "react";
import AuthContext from "../../Navigation/AuthWrapper";
import { useNavigate } from "react-router-dom";

const DashboardRedirect = () => {
  //const role_id = useSelector((state) => state.auth.role_id); // Get the role_id from Redux state
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  useEffect(() => {
    if (auth.role_id === 2) {
      navigate("/GymDash");  // Navigate to Gym Dashboard
    } else if (auth.role_id === 3) {
      navigate("/TrainerDash");  // Navigate to Trainer Dashboard
    } else {
      navigate("/Login");  // If no role_id or not authenticated, navigate to login
    }
  }, [auth.role_id, navigate]);

  return null; // No UI is needed, just handle redirection
};

export default DashboardRedirect;
