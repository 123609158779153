import React from "react";
import OnboardingTemplate from "../../../Components/Onboarding/OnboardingTemplate";
import OnboardingLocation from "../../../Components/Onboarding/OnboardingLocation";

export default function LocationSetup({ nextAction, previousAction, screenNum = 1, screenTotal = 1 }) {
  const showRadius = false; 
  return (
    <>
      <OnboardingTemplate
        component={<OnboardingLocation radiusOpt={showRadius} />}
        screenNum={screenNum}
        screenTotal={screenTotal}
        nextAction={nextAction}
        previousAction={previousAction}
        title={"Location"}
      />
    </>
  );
}