import React, { useState, useEffect, useContext } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Box, Typography, Button, Link } from "@mui/material"; // MUI components
import { useTheme } from '@mui/material/styles'; // Access MUI theme for dark/light mode
import AuthContext from "../../Navigation/AuthWrapper";
import CleanWrapper from "../../Components/Common/CleanWrapper";
import Setup from "../../Screens/Wizard/SiteOnboarding/Setup";
import SpaceScreen from "../../Screens/Wizard/SiteOnboarding/Space";
import Amenities from "../../Screens/Wizard/SiteOnboarding/Amenities";
import LocationSetup from "./SiteOnboarding/LocationSetup";
import GallerySetup from "./SiteOnboarding/GallerySetup";
import ScheduleSetup from "./SiteOnboarding/ScheduleSetup";
import SetupComplete from "../../Screens/Wizard/SiteOnboarding/SetupComplete";
import BusinessDescription from "../../Components/Onboarding/BusinessDescription";
import SpecialInstructions from "../../Components/Onboarding/SpecialInstructions";

import { useSnackbar } from "../../Navigation/AuthWrapper";

const screens = {
  Setup: { component: Setup, next: "Description", prev: null, screenNum: 0, props: { role: "site" } },
  Description: { component: BusinessDescription, next: "Gallery", screenNum: 1, prev: "Setup" },
  Gallery: { component: GallerySetup, next: "Space", screenNum: 2, prev: "Description" },
  Space: { component: SpaceScreen, next: "Amenities", screenNum: 3, prev: "Gallery" },
  Amenities: { component: Amenities, next: "Location", screenNum: 4, prev: "Space" },
  Location: { component: LocationSetup, next: "Schedule", screenNum: 5, prev: "Amenities" },
  Schedule: { component: ScheduleSetup, next: "Special", screenNum: 6, prev: "Location" },
  Special: { component: SpecialInstructions, next: "SetupComplete", screenNum: 7, prev: "Schedule" },
  SetupComplete: { component: SetupComplete, next: "Done", screenNum: 8, prev: null },
  Skip: { component: "Skip", prev: null },
  Done: { component: "Done", prev: null },
};

const SiteOnboarding = () => {
  const [activeScreen, setActiveScreen] = useState("Setup");
  const [isAuthComplete, setIsAuthComplete] = useState(false);
  const [isOTPVerified, setIsOTPVerified] = useState(false);
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const location = useLocation();
  const theme = useTheme();

  const { showSnackbar } = useSnackbar(); 

  useEffect(() => {
    // Redirect if role_id is not trainer
    if (auth.role_id === 3) {
      navigate("/TrainerOnboarding"); // Redirect site owners
    }
  }, [auth.role_id, navigate]);

  useEffect(() => {
    const hash = location.hash.replace("#", "") || "intro";
    const screenKey = Object.keys(screens).find((key) => key.toLowerCase() === hash);
    if (screenKey) {
      setActiveScreen(screenKey);
    } else {
      setActiveScreen("Setup");
    }
  }, [location.hash]);

  useEffect(() => {
    if (auth.token && isOTPVerified) {
      setIsAuthComplete(true);
    }
  }, [auth.token, isOTPVerified]);

  const handleAuthSuccess = () => {
    console.log("OTP verified successfully");
    setIsOTPVerified(true);
  };

  const renderScreen = () => {
    const screenInfo = screens[activeScreen];
    const ScreenComponent = screenInfo.component;

    if (ScreenComponent === "Skip" || ScreenComponent === "Done") {
      return <Navigate to="/GymDash" />;
    }

    return ScreenComponent ? (
      <ScreenComponent
        {...(screenInfo.props || {})}
        goToStep={(screen) => setActiveScreen(screen)}
        nextAction={() => setActiveScreen(screenInfo.next)}
        previousAction={() => {
          if (screenInfo.prev) {
            setActiveScreen(screenInfo.prev);
          }
        }}
        screenNum={screenInfo.screenNum}
        screenTotal={8}
      />
    ) : null;
  };

  return (
    <CleanWrapper>
      <Box className="site-onboard">
        {renderScreen()}
      </Box>
    </CleanWrapper>
  );
};

export default SiteOnboarding;
