import React from "react";
import OnboardingTemplate from "../../../Components/Onboarding/OnboardingTemplate";
import OnboardingGallery from "../../../Components/Onboarding/OnboardingGallery";

export default function GallerySetup({ nextAction, previousAction, screenNum = 1, screenTotal = 1 }) {
  return (
    <>
      <OnboardingTemplate
        component={<OnboardingGallery />}
        screenNum={screenNum}
        screenTotal={screenTotal}
        nextAction={nextAction}
        previousAction={previousAction}
        title={"Photos"}
      />
    </>
  );
}