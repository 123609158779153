import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import OperationTime from "../../../Components/Calendar/OperationTimeGrid";

import { Tabs, Tab, TextField, useTheme, Typography, Box } from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker, PickersDay } from "@mui/x-date-pickers";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import moment from "moment";
import { globalStyles, spacingStyles } from "../../../assets/styles/global";
import { setBlackout } from "../../../Services/Redux/Slices/BlackoutDates.Slice";

import useMediaQuery from "@mui/material/useMediaQuery";

import "../../../assets/styles/overides_material.css"; 

import { updateBlackOutDatesAsync } from "../../../Services/Redux/Thunks/updateBlackOutDates";

export default function UserCalendar() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.auth.token);

  // Tab state
  const [tabIndex, setTabIndex] = useState(0); // Default to 0 (Availability tab)
  const [viewDate, setViewDate] = useState(moment()); // Track the currently viewed month

  // Get blackout dates from Redux state
  const blackOuts = useSelector((state) => state.blackout);
  const [blackoutDates, setBlackoutDates] = useState(blackOuts || []);

  // Ref for the bottom of the component
  const bottomRef = useRef(null);

  useEffect(() => {
    // Sync local state with Redux store if it changes
    setBlackoutDates(blackOuts);
  }, [blackOuts]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  // Toggle a date in the blackout dates list and dispatch to Redux
  const toggleDate = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
  
    setBlackoutDates((prevDates) => {
      let updatedDates;
  
      if (prevDates.includes(formattedDate)) {
        updatedDates = prevDates.filter((d) => d !== formattedDate);
      } else {
        updatedDates = [...prevDates, formattedDate];
      }
  
      // Dispatch the thunk with updated blackout dates
      dispatch(updateBlackOutDatesAsync({ data: updatedDates, token: token })); // Ensure you pass the correct token
  
      return updatedDates;
    });
  
    // Scroll to bottom when a date is clicked
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Custom rendering of each day to highlight blackout dates
  const renderDay = (day, selectedDate, pickersDayProps) => {
    const isSelected = blackoutDates.includes(moment(day).format("YYYY-MM-DD"));

    return (
      <PickersDay
        {...pickersDayProps}
        day={day}
        selected={isSelected}
        sx={{
          ...(isSelected && {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          }),
        }}
      />
    );
  };

  return (
    <div style={spacingStyles.desktopMaxWidth}>

      <Box sx={{  padding: "20px 0 40px" }}>
        <Typography sx={{ maxWidth: "100vw" }} variant="h4" color={theme.palette.text.primary}>
          Availability
        </Typography>

        {/* Tabs to switch between Availability and Blackout Dates */}
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          scrollButtons={isMobile ? "auto" : false}
          centered
          sx={{ maxWidth: "100vw" }}
        >
          <Tab label="Schedule" />
          <Tab label="Blockouts" />
        </Tabs>
      </Box>



      {tabIndex === 0 && (
        <div className="calendar-contain ">
          <div style={globalStyles.fullWidth}>
            <OperationTime />
          </div>
        </div>
      )}

      {tabIndex === 1 && (
        <div style={{ paddingBottom: 10, paddingRight: 8, paddingLeft: 8 , }} >
          <Box
            style={{
              marginRight: "auto",
              //border: `2px dashed ${theme.palette.text.secondary}`,
              borderRadius: "var(--radius)",
              paddingTop: "15px",
              backgroundColor: theme.palette.background.paper,
              boxShadow: theme.shadows[2],
            }}
            className="calendar-contain"
          >
            <Typography variant="h4" sx={{ textAlign: "center", marginBottom: 0, color: theme.palette.text.primary }}>
              Blockout Dates
            </Typography>
            <Typography variant="body" color={theme.palette.text.primary}>
              Select all days that apply.
            </Typography>

            <LocalizationProvider dateAdapter={AdapterMoment}>
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                openTo="day"
                value={viewDate}
                onChange={(newDate) => {
                  toggleDate(newDate);
                }}
                onMonthChange={(newMonth) => {
                  setViewDate(newMonth);
                }}
                renderDay={renderDay}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      input: {
                        color: theme.palette.text.primary,
                      },
                      "& .MuiInputBase-root": {
                        color: theme.palette.text.primary,
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: theme.palette.divider,
                        },
                        "&:hover fieldset": {
                          borderColor: theme.palette.primary.main,
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: theme.palette.primary.main,
                        },
                      },
                    }}
                  />
                )}
                componentsProps={{
                  switchViewButton: {
                    sx: {
                      color: theme.palette.text.primary,
                    },
                  },
                  toolbar: {
                    sx: {
                      "& .MuiTypography-root": {
                        color: theme.palette.text.primary,
                      },
                    },
                  },
                  calendarHeader: {
                    sx: {
                      backgroundColor: "transparent",
                      "& .MuiPickersCalendarHeader-labelContainer": {
                        backgroundColor: "transparent",
                        color: theme.palette.text.primary,
                      },
                      "& .MuiPickersCalendarHeader-root": {
                        color: theme.palette.text.primary,
                      },
                      "& .MuiPickersArrowSwitcher-root": {
                        color: theme.palette.text.primary,
                      },
                      "& .MuiIconButton-root": {
                        color: theme.palette.text.primary,
                      },
                    },
                  },
                  day: {
                    sx: {
                      // backgroundColor: 'transparent',
                    },
                  },
                  paper: {
                    sx: {
                      backgroundColor: "transparent",
                      boxShadow: "none",
                    },
                  },
                }}
                minDate={moment()}
                maxDate={moment().add(12, "months")}
              />
            </LocalizationProvider>
          </Box>

          {/* Display selected Blockout dates */}
          <div style={{ marginTop: "20px", paddingBottom: "40px" }}>
            <Typography variant="h5" color={theme.palette.text.primary} sx={{ marginBottom: "20px" }}>
              Selected Blockout Dates:
            </Typography>

            {blackoutDates.length > 0 ? (
              blackoutDates.map((date, index) => (
                <Typography key={index} sx={{ textAlign: "left" }} color={theme.palette.text.primary}>
                  {moment(date).format("MMMM Do, YYYY")}
                </Typography>
              ))
            ) : (
              <Typography color={theme.palette.text.secondary}>
                No Blockout dates selected
              </Typography>
            )}
          </div>
          {/* Ref for scrolling */}
          <div ref={bottomRef} />
        </div>
      )}
    </div>
  );
}
