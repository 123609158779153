import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Masonry from "@mui/lab/Masonry";
import moment from "moment";
import { MdDelete, MdAdd } from "react-icons/md";
import SlideInPanel from "../../Components/Modals/slideInPanel";
import ImageUploader from "../../Components/Common/ImageUpload";
import DeleteConfirmationModal from "../../Screens/MobileGallery/DeleteConfirmationModal";
import getErrorMessage from "../../Services/Helpers/getErrorMessage";
import { getMyGallery, deletePhoto } from "../../Services/APIHandlers/ProfileApi";
import { colors, globalStyles, spacingStyles } from "../../assets/styles/global";
import { useTheme } from "@mui/material/styles"; 

const CardItem = ({ image, picture_id, editEnabled, setDeleteId }) => (
  <div style={styles.imageCard}>
    <img src={image} width={"100%"} style={{ borderRadius: 20, minHeight: 100 }} alt="card" />
    {editEnabled && (
      <span style={styles.deleteButton} onClick={() => setDeleteId(picture_id)}>
        <MdDelete size={28} color={colors.white.color} />
      </span>
    )}
  </div>
);

const MasonaryCardView = ({ images, editEnabled = true, setDeleteId }) => {
  const limitedImages = images.slice(0, 4); // Limit the number of images displayed to 4
  return (
    <Masonry columns={2} spacing={2} sx={{ my: 2 }}>
      {limitedImages.map(({ url, picture_id }, index) => (
        <CardItem
          key={url + index}
          image={url}
          picture_id={picture_id}
          editEnabled={editEnabled}
          setDeleteId={setDeleteId}
        />
      ))}
    </Masonry>
  );
};

const OnboardingGallery = ({ editEnabled = true, gallery }) => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const [deleteId, setDeleteId] = useState(null);
  const [galleryData, setGalleryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isPanelOpen, setPanelOpen] = useState(false);

  const theme = useTheme();

  const categorizeByDate = (gallery) => {
    const sortedGallery = [...gallery].reverse();
    const categorizedData = sortedGallery.reduce((acc, obj) => {
      const date = moment(obj.date).format("DD MMM yyyy");
      if (!acc[date]) acc[date] = [];
      if (obj.url) acc[date].push(obj);
      return acc;
    }, {});
    setGalleryData(categorizedData);
  };

  const fetchMyGallery = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getMyGallery({ token });
      //console.log(response); 
      const gallery = response.data?.map(({ date, url, picture_id }) => ({ date, url, picture_id }));
      categorizeByDate(gallery);
    } catch (error) {
      console.error(">>>Error", error);
    } finally {
      setLoading(false);
    }
  }, [token]);

  const onLoadProfilePicSuccess = async (picture_id) => {
    setPanelOpen(false);
    fetchMyGallery();
  };

  const handleDelete = async () => {
    try {
      const response = await deletePhoto({ token, picture_id: String(deleteId) });
      console.log(">>message", response.data);
      setDeleteId(null);
      fetchMyGallery();
    } catch (error) {
      alert(getErrorMessage(error));
    }
  };

  useEffect(() => {
    if (!gallery) {
      fetchMyGallery();
    } else {
      categorizeByDate(gallery);
    }
  }, [fetchMyGallery, gallery]);

  return (
    <div>
      {!!deleteId && (
        <DeleteConfirmationModal onDelete={handleDelete} onClose={() => setDeleteId(null)} />
      )}

      <div style={spacingStyles.w100}>
        {!Object.keys(galleryData)?.length && !loading && (
          <div style={{ ...globalStyles.alignCenter, ...globalStyles.mt30 }}>
            <span style={{ ...globalStyles.h3, color: theme.palette.text.primary }}>No images added</span>
          </div>
        )}

        {galleryData &&
          Object.keys(galleryData).map((key) => (
            <div key={key}>
              <MasonaryCardView images={galleryData[key]} editEnabled={editEnabled} setDeleteId={setDeleteId} />
            </div>
          ))}
      </div>

      {editEnabled && (
        <>
          <div
            className="Add-Button"
            style={styles.addButton}
            onClick={() => setPanelOpen(true)}
          >
            <MdAdd color={colors.white.color} size={60} />
          </div>

          <SlideInPanel
            isOpen={isPanelOpen}
            closePanel={() => setPanelOpen(false)}
            content={() => <ImageUploader onSuccess={onLoadProfilePicSuccess} />}
          />
        </>
      )}
    </div>
  );
};

export default OnboardingGallery;

const styles = {
  addButton: {
    borderRadius: 60,
    position: "relative",
    margin: "auto",
    width: '60px', 
    backgroundColor: colors.primary.color,
    alignSelf: "center",
    cursor: "pointer",
    marginTop: 20,
  },
  imageCard: {
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 20,
  },
  deleteButton: {
    backgroundColor: colors.gray.color,
    position: "absolute",
    bottom: 30,
    right: 10,
    borderRadius: 40,
    padding: 5,
    cursor: "pointer",
  },
};
