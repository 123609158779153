import React, { useMemo } from "react";
import OnboardingTemplate from "../../../Components/Onboarding/OnboardingTemplate";
import MatchMeTopicComponent from "../../../Components/Onboarding/MatchMeTopic";
import { useSelector } from "react-redux";
import { Container } from "@mui/material";

const MatchMeTopic = ({
  topic,
  nextAction,
  previousAction,
  screenNum,
  screenTotal,
  saveResponses,
}) => {
  // Retrieve all topic data from Redux store
  const matchMeData = useSelector((state) => state.matchMe);

  console.log("..matchMeData", matchMeData);

  const disabled = useMemo(() => {
    if (!topic) return true;

    return !matchMeData[topic].answers.some(
      ({ select }) => Number(select) === 5
    );
  }, [matchMeData, topic]);

  return (
    <Container maxWidth="xs">
      <OnboardingTemplate
        component={<MatchMeTopicComponent topic={topic} />}
        screenNum={screenNum}
        screenTotal={screenTotal}
        nextAction={nextAction}
        previousAction={previousAction}
        saveResponses={saveResponses}
        disabled={disabled}
        title={topic}
      />
    </Container>
  );
};

export default MatchMeTopic;
