import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import { FaRegCircleXmark } from "react-icons/fa6";
import { BsExclamationTriangle } from "react-icons/bs";
import { Box, Typography, Icon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { spacingStyles } from "../../assets/styles/global";

const WalletDetails = ({ details }) => {
  const theme = useTheme(); // Using the theme for colors and spacing

  // Array to store details for each section
  const sections = [
    {
      icon: <FaCheckCircle size={30} color={theme.palette.text.secondary} />,
      label: "Bookings",
      value: details?.current_stats?.bookings || 0,
    },
    {
      icon: <FaRegCircleXmark size={30} color={theme.palette.text.secondary} />,
      label: "Cancels",
      value: details?.current_stats?.cancelled || 0,
    },
    {
      icon: <BsExclamationTriangle size={30} color={theme.palette.text.secondary} />,
      label: "No-Shows",
      value: details?.current_stats?.noShows || 0,
    },
  ];

  // Shared styles for sections
  const sectionStyles = {
    mt: 3,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 1,
    pl: 1,
    pr: 2,
    borderBottom: '1px solid gray',
    // mb: 2,
  };

  return (
    <Box>
      <Box
        sx={{
          mt: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {/* Loop through the sections array */}
        {sections.map((section, index) => (
          <Box key={index} sx={sectionStyles}>
            <Box display="flex" alignItems="center">
              {section.icon}
              {/* <Icon sx={{ mr: 2 }}>{section.icon}</Icon> */}
              <Typography
                variant="h6"
                color={theme.palette.text.primary}
                fontWeight={600}
                sx={{ marginLeft: 1 }}
              >
                {section.label}
              </Typography>
            </Box>
            <Typography variant="h4" color={theme.palette.text.primary}>
              {section.value}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default WalletDetails;
