import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  verify_SMSPin,
  emailOTP,
  verify_EmailPin,
} from "../../Services/APIHandlers/AuthApi";
import { addBusiness } from "../../Services/Redux/Slices/Business.Slice";
import {
  createAuth,
  addAccountData,
} from "../../Services/Redux/Slices/Auth.Slice";
import { addUser } from "../../Services/Redux/Slices/User.Slice";

import { Button } from "../Common/Buttons/Button";

import { spacingStyles } from "../../assets/styles/global";

import { addBookingList } from "../../Services/Redux/Slices/Booking.Slice";

const OTPForm = ({ onSuccess, mode = "dark" }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const [value, setValue] = useState("");
  const [error, setError] = useState("");
  const phone = useSelector((state) => state.user.phone);
  const [sendCodeTo, setSend] = useState("text");

  const getEmailOtp = async () => {
    setSend("email");

    try {
      await emailOTP(token);
    } catch (err) {
      console.error(err);
    }
  };

  const submitPin = async () => {
    if (value.length !== 4) {
      setError("Invalid Pin Code");
      return;
    }
    setError("");

    try {
      let response;

      if (sendCodeTo === "email") {
        response = await verify_EmailPin(value, token);
      } else {
        response = await verify_SMSPin(value, token);
      }

     //console.log(response);

      if (response.data?.status === "invalid") {
        setError("Invalid Pin Code");
        return;
      }

      await dispatch(addUser({ ...response.data.userData }));

      if (
        response.data.userData.role_id === "2" ||
        response.data.userData.role_id === "3"
      ) {
        await dispatch(addBusiness({ ...response.data.businessData }));
        await dispatch(addBookingList({ ...response.data.businessData }));
        await dispatch(addAccountData({ ...response.data.accountData }));
      }

      await dispatch(
        createAuth({ token: token, role_id: response.data.userData.role_id })
      );
     // console.log(response.data.userData, response.data.userData.role_id); 
      onSuccess(response.data.userData.role_id);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <div>
        {!error && (
          <h2 className="h2 center">
            Enter the verification code we {sendCodeTo}ed to you.
          </h2>
        )}
        {error && <p className="errorText">{error}</p>}

        {phone && <p>Pin was sent to {phone}</p>}

        <div style={styles.codeInput}>
          {Array.from({ length: 4 }).map((_, index) => (
            <input
              type="text"
              maxLength="1"
              className={`${error ? "errorInput" : ""}`}
              style={{
                ...styles.cell,
                ...spacingStyles.mr5,
                ...spacingStyles.ml5,
              }}
              key={index}
              value={value[index] || ""}
              onChange={(e) => {
                const updatedValue = value.split("");
                updatedValue[index] = e.target.value;
                setValue(updatedValue.join(""));
                if (e.target.value !== "") {
                  if (index < 3) {
                    document.querySelectorAll("input")[index + 1].focus();
                  } else {
                    document.querySelectorAll("input")[index].blur();
                  }
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Backspace" && e.target.value === "") {
                  e.preventDefault();
                  if (index > 0) {
                    document.querySelectorAll("input")[index - 1].focus();
                  }
                }
              }}
              autoFocus={index === 0}
            />
          ))}
        </div>
        <div
          className="mt40 pb100"
          style={{ ...spacingStyles.formSpace, ...spacingStyles.p10 }}
        >
          <Button fill="solid" text="Verify ID" onClick={submitPin}></Button>
        </div>

        <div className="">
          <h2 className="h2 center">Didn't receive our text?</h2>
          <p className="p center gray pt5">
            <span
              className="textButton title orange"
              onClick={() => getEmailOtp()}
            >
              Email me{" "}
            </span>{" "}
            the verification code.
          </p>
        </div>
      </div>
    </>
  );
};

export default OTPForm;

const styles = {
  cell: {
    backgroundColor: "#FFFFFF26",
    borderRadius: 15,
    overflow: "hidden",
    height: 55,
    width: 45,
    lineHeight: 85,
    marginLeft: "2%",
    marginRight: "2%",
    fontSize: 55,
    color: "white",
    textAlign: "center",
    borderColor: "white",
    borderWidth: 1,
    padding: 5,
  },
  header: {
    justifyContent: "flex-end",
    height: "25%",
  },
  codeInput: {
    justifyContent: "flex-start",
    paddingTop: "40px",
    alignItems: "center",
    height: "85%",
  },
};
