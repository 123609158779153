import React from "react";
import { Stack, TextField, useTheme } from "@mui/material";
import { spacingStyles } from "../../assets/styles/global";

function ProfileFieldInput({ label, value, onChange, onFocus, onBlur, isEditing, disabled }) {
  const theme = useTheme();

  // Dark/Light mode dependent styles
  const textColor = theme.palette.mode === "dark" ? theme.palette.grey[300] : theme.palette.text.primary;

  return (
    <Stack direction="column" alignItems="flex-start" spacing={2} sx={{ width: "100%" }}>
      {/* <h3 style={{ ...spacingStyles.pb10, color: textColor }}>{label}:</h3>
       */}
      {isEditing ? (
        <TextField
          label={label}
          variant="outlined"
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          fullWidth
          disabled={disabled}
          sx={{
            backgroundColor: theme.palette.background.paper,
            borderRadius: 1,
            '& .MuiInputBase-input': {
              cursor: disabled ? "default" : "pointer",
            },
          }}
        />
      ) : (
        <div style={{
          width: "100%", 
          padding: "10px", 
          border: "1px solid #ccc", 
          borderRadius: "4px", 
          backgroundColor: disabled ? "#000" : "#fff", 
          color: textColor,
          cursor: "default"
        }}>
          {value || " "}
        </div>
      )}
    </Stack>
  );
}

export default ProfileFieldInput;
