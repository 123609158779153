import { createSlice } from "@reduxjs/toolkit";
import { updateBlackOutDatesAsync } from "../Thunks/updateBlackOutDates.js";

const initialState = [];

const BlackoutDatesSlice = createSlice({
  name: "blackoutDates",
  initialState: initialState,
  reducers: {
    setBlackout(state, action) {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateBlackOutDatesAsync.pending, (state) => {
        //state.status = 'loading';
      })
      .addCase(updateBlackOutDatesAsync.fulfilled, (state, action) => {

        console.log("BlackOutDates Update: ", action.payload);
        return action.payload.data;
        // state.status = 'succeeded';
        // state.blackoutDates = action.payload;
      })
      .addCase(updateBlackOutDatesAsync.rejected, (state, action) => {
        //state.status = 'failed';
        //state.error = action.payload;
      });
  },
});

export const { setBlackout } = BlackoutDatesSlice.actions;

export default BlackoutDatesSlice.reducer;
