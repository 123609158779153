import React, { useContext, useEffect } from 'react';
import AuthContext from './AuthWrapper';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTheme } from '@mui/material/styles';

const OnboardingSideMenu = ({ activeScreen, onMenuClick, completionStatus }) => {
  const auth = useContext(AuthContext); // Access role_id from AuthContext
  const theme = useTheme();

  const getMenuClass = (screen) => {
    const spaceRelatedScreens = ["Space", "Capacity", "SpaceType", "FloorCovering"];
    const amenitiesRelatedScreens = ["Equipment", "Extras", "Amenities"];

    if (screen === "Space" && spaceRelatedScreens.includes(activeScreen)) {
      return 'active-sidemenu-item';
    }

    if (screen === "Amenities" && amenitiesRelatedScreens.includes(activeScreen)) {
      return 'active-sidemenu-item';
    }

    return activeScreen === screen ? 'active-sidemenu-item' : '';
  };

  // useEffect(() => {
  //   console.log("Onboarding Navigation", auth.role_id);
  // }, [auth.role_id]);

  // Menu items for Site Onboarding (role_id = 2)
  const siteOnboardingMenuItems = [
    { screen: "Setup", label: "Intro" },
    { screen: "Description", label: "Description" },
    { screen: "Gallery", label: "Photos" },
    { screen: "Space", label: "Space Details" },
    { screen: "Amenities", label: "Amenities" },
    { screen: "Location", label: "Location" },
    { screen: "Schedule", label: "Availability" },
    { screen: "Special", label: "Special Instructions" },
    { screen: "SetupComplete", label: "Agreement" }
  ];

  // Menu items for Trainer Onboarding (role_id = 3)
  const trainerOnboardingMenuItems = [
    { screen: "Setup", label: "Intro" },
    { screen: "DemographicInfo", label: "Demographic" },
    { screen: "Certification", label: "Certifications" },
    { screen: "Insurance", label: "Insurance" },
    // { screen: "Coach", label: "Coach" },
    // { screen: "Goal", label: "Goal" },
    // { screen: "Area", label: "Area" },
    // { screen: "Motivate", label: "Motivate" },
    // { screen: "Success", label: "Success" },
    // { screen: "Location", label: "Location" },
    // { screen: "Schedule", label: "Availability" },
    { screen: "SetupComplete", label: "Agreement" }
  ];

  // Determine which menu items to render based on role_id from AuthContext
  const menuItems = (auth.role_id === 2 ? siteOnboardingMenuItems : trainerOnboardingMenuItems);
  //console.log("Onboarding Role", auth.role_id, menuItems); 
  return (
    <div className="onboard-side-menu">
      {menuItems.map((item) => (
        <MenuItem
          key={item.screen}
          className={getMenuClass(item.screen)}
          onClick={() => onMenuClick(item.screen)}
          sx={{ marginLeft: '20px' }}
        >
          <ListItemIcon style={{ minWidth: '40px' }}>
            {completionStatus[item.screen] && <CheckCircleIcon color="success" />} {/* Show checkmark if completed */}
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              style: { color: theme.palette.text.primary, textAlign: "left" }
            }}
            primary={item.label} />
        </MenuItem>
      ))}
    </div>
  );
};

export default OnboardingSideMenu;
