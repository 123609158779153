import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Stack, TextField, Button, Typography, useTheme } from "@mui/material";
import OnboardingTemplate from "../../Components/Onboarding/OnboardingTemplate";
import { updateSpaceDetailsAsync } from "../../Services/Redux/Thunks/updateSpaceDetails";
import { setSpecialInstructions, setSpecialRules, setSpecialAccess, selectOnboardingState } from "../../Services/Redux/Slices/Onboarding.Slice";

const SpecialInstructions = ({ nextAction, previousAction, screenNum = 1, screenTotal = 1 }) => {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);
    const { instructions } = useSelector(selectOnboardingState);
    const onboardingState = useSelector(selectOnboardingState);
    const theme = useTheme();

    // Dark/Light mode dependent styles
    const textColor = theme.palette.mode === "dark" ? theme.palette.grey[300] : theme.palette.text.primary;

    const saveResponses = async () => {
        try {
            const response = await dispatch(updateSpaceDetailsAsync({ data: onboardingState, token }));
            if (response?.payload) {
                return response.payload;
            } else {
                throw new Error("No data returned from saveResponses");
            }
        } catch (error) {
            console.error("Failed to save responses:", error);
            return null;
        }
    };

    return (
        <OnboardingTemplate
            component={
                <Stack spacing={10} sx={{ textAlign: "left" }}>
                    <Stack spacing={2}>
                        <Typography variant="h5" sx={{ color: textColor }}>
                            Got anything we should know about?
                        </Typography>
                        <Typography variant="body2" sx={{ color: theme.palette.grey[500] }}>
                            Does your space have something unique that people should know about? For example: is your main entrance in the rear?
                        </Typography>
                        <TextField
                            label="Special Instructions...."
                            onChange={(e) => dispatch(setSpecialInstructions(e.target.value))}
                            fullWidth
                            multiline
                            rows={3}
                            variant="outlined"
                        />
                    </Stack>
                    
                    <Stack spacing={2}>
                        <Typography variant="h5" sx={{ color: textColor }}>
                            Add your own house rules in your Host Use Agreement
                        </Typography>
                        <Typography variant="body2" sx={{ color: theme.palette.grey[500] }}>
                            This is where you can add your own special rules and users will agree to them when they book your space.
                        </Typography>
                        <TextField
                            label="Rules for Hoppers..."
                            onChange={(e) => dispatch(setSpecialRules(e.target.value))}
                            fullWidth
                            multiline
                            rows={3}
                            variant="outlined"
                        />
                    </Stack>

                    <Stack spacing={2}>
                        <Typography variant="h5" sx={{ color: textColor }}>
                            Instructions for Hoppers
                        </Typography>
                        <Typography variant="body2" sx={{ color: theme.palette.grey[500] }}>
                            Tell users what they need to know in order to use your space when they start their booking. Is there a door code to get access to the space? Do they have to check in with a specific person? The more details you provide will limit any confusion at the start of their booking.
                        </Typography>
                        <TextField
                            label="Instructions for Hoppers..."
                            onChange={(e) => dispatch(setSpecialAccess(e.target.value))}
                            fullWidth
                            multiline
                            rows={3}
                            variant="outlined"
                        />
                    </Stack>
                </Stack>
            }
            screenNum={screenNum}
            screenTotal={screenTotal}
            nextAction={nextAction}
            previousAction={previousAction}
            title="Special Instructions"
            saveResponses={saveResponses}
        />
    );
};

export default SpecialInstructions;
