import React, { createContext, useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Snackbar, Alert, Typography, useTheme } from "@mui/material";
import { RenderRoutes, RenderMenu } from "./RenderNavigation";
import { clearAuth } from "../Services/Redux/Slices/Auth.Slice";
import { getLoginData } from "../Services/APIHandlers/ProfileApi";

import { colors } from "../assets/styles/global";

// Auth context
const AuthContext = createContext();
export default AuthContext;

// Snackbar context for global notifications
const SnackbarContext = createContext();

export const AuthWrapper = ({ darkMode, toggleDarkMode }) => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const location = useLocation();

  const token = useSelector((state) => state.auth.token);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const role_id = Number(useSelector((state) => state.auth.role_id));

  const theme = useTheme(); // Access the current theme

  // Completion status for steps
  const [completionStatus, setCompletionStatus] = useState({
    Description: false,
    Gallery: false,
    Space: false,
    Amenities: false,
    Location: false,
    Schedule: false,
    Special: false,
    SetupComplete: false,
  });

  // Snackbar states
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");

  // Mark step as complete
  const markAsComplete = (step) => {
    setCompletionStatus((prevState) => ({
      ...prevState,
      [step]: true,
    }));
  };

  // Logout handler
  const logout = () => {
    dispatch(clearAuth());
    window.location.reload();
  };

  // Login handler
  const login = () => {
    navigation("/Login");
  };

  // Handle dark mode toggle
  useEffect(() => {
    if (darkMode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  }, [darkMode]);



  // Fetch login data if authenticated
  useEffect(() => {
    if (token && isAuthenticated) {
      getLoginData(token);
    }
  }, [isAuthenticated, token]);

  // Snackbar utility functions
  const showSnackbar = (message, severity = "info") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const hideSnackbar = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    console.log("AuthWrapper Navigation", role_id);
  }, [role_id]);

  return (
    <AuthContext.Provider
      value={{
        token,
        role_id,
        logout,
        login,
        darkMode,
        toggleDarkMode,
        markAsComplete,
      }}
    >
      <SnackbarContext.Provider value={{ showSnackbar, hideSnackbar }}>
        {/* {console.log("AuthContext Provider role_id:", role_id)} */}
        <RenderMenu role_id={role_id}  />
        <div className={`content-container`}>
          <RenderRoutes />
        </div>

        {/* Global Snackbar for notifications */}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          onClose={hideSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            //onClose={hideSnackbar}
            severity={snackbarSeverity}
            sx={{
              width: "100%",
              //backgroundColor: theme.palette.background.paper, // Use theme color
              //color: theme.palette.text.primary, // Typography color from theme
              marginTop: "20px",
              "& .MuiAlert-icon": {
                color: colors.black.color, // Change the icon color here
              },
            }}
          >
            <Typography
              sx={{
                width: "100%",
                color: colors.black.color, // Typography color from theme
                //fontFamily: theme.typography.fontFamily // Use theme typography
              }}
            >
              {snackbarMessage}
            </Typography>
          </Alert>
        </Snackbar>
      </SnackbarContext.Provider>
    </AuthContext.Provider>
  );
};

// Custom hook for using the Snackbar
export const useSnackbar = () => useContext(SnackbarContext);
