import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography, List, ListItem, ListItemText, useTheme } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { globalStyles } from "../../assets/styles/global";
import config from "../../config";
import { addBusiness } from "../../Services/Redux/Slices/Business.Slice";

const CertQuestionWithUpload = ({ question, onSubmit }) => {
  const [answer, setAnswer] = useState(null); // Tracks the Yes/No answer
  const [file, setFile] = useState(null); // Tracks the uploaded file
  const [uploadedFiles, setUploadedFiles] = useState([]); // Tracks the list of uploaded files
  const [certificationType, setCertificationType] = useState(""); // Tracks the certification type input
  const [fileError, setFileError] = useState(null); // Tracks file validation errors
  const [uploading, setUploading] = useState(false); // Tracks the file upload status
  const theme = useTheme(); // Get the theme for dark/light mode

  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const token = auth.token;

  // Handle Yes/No answer
  const handleAnswerChange = (event) => {
    const value = event.target.value;
    setAnswer(value);
    dispatch(addBusiness({ certified: value === "yes" }));
  };

  // Handle Certification Type input change and dispatch update to the business store
  const handleCertificationChange = (event) => {
    const value = event.target.value;
    setCertificationType(value);
    dispatch(addBusiness({ certification: value })); // Update the certification field in the business store
  };

  // Validate and handle file upload (supports PDF, JPG, JPEG, PNG)
  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileSizeInMB = selectedFile.size / 1024 / 1024; // Convert bytes to MB
      const fileType = selectedFile.type;

      // Allow only images (jpg, jpeg, png) or pdf and file size <= 3MB
      if (
        (fileType === "image/jpeg" || fileType === "image/png" || fileType === "image/jpg" || fileType === "application/pdf") &&
        fileSizeInMB <= 3
      ) {
        setFile(selectedFile);
        setFileError(null); // Clear any previous errors
        await uploadFile(selectedFile); // Automatically upload the file when selected
      } else {
        // Set appropriate error message
        if (fileSizeInMB > 3) {
          setFileError("File size exceeds the maximum limit of 3MB.");
        } else {
          setFileError("Only JPG, JPEG, PNG, or PDF files are allowed.");
        }
        setFile(null); // Reset the file input
      }
    }
  };

  // Automatically upload the file when selected
  const uploadFile = async (selectedFile) => {
    setUploading(true); // Set uploading state to true to disable the button
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("token", token);

    try {
      const response = await fetch(config.apiUrl + "Upload/singleFileUpload", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        console.log("Upload successful:", result);
        // Add the uploaded file to the list
        setUploadedFiles((prevFiles) => [...prevFiles, selectedFile.name]);
        onSubmit({ fileId: result.data.file_id }); // Pass the file ID back to the parent component
      } else {
        console.error("Upload failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error during upload:", error.message);
    } finally {
      setUploading(false); // Set uploading state back to false when done
    }
  };

  // Dark/Light mode dependent styles
  const textColor = theme.palette.text.primary;

  return (
    <Box sx={{ ...globalStyles.w100 }}>
      <Stack spacing={3} sx={{ textAlign: "left" }}>
        <Stack spacing={2}>
          <Typography variant="h5" sx={{ color: textColor }}>
            {question}
          </Typography>

          <RadioGroup
            aria-label="yesno"
            name="yesno"
            value={answer}
            onChange={handleAnswerChange}
            row
          >
            <FormControlLabel sx={{ color: textColor }} value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel sx={{ color: textColor }} value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </Stack>

        {/* Conditionally render Certification Type input if 'Yes' is selected */}
        {answer === "yes" && (
          <>
            <Stack spacing={2}>
              <Typography variant="h6" sx={{ color: textColor }}>
                What are your certification types?
              </Typography>
              <TextField
                label="Certifications"
                variant="outlined"
                value={certificationType}
                onChange={handleCertificationChange} // Dispatch certification change here
                fullWidth
              />
            </Stack>

            <Stack spacing={2}>
              <Typography variant="body2" sx={{ color: theme.palette.grey[500] }}>
                Please upload your proof document (JPG, JPEG, PNG, or PDF, max 3MB).
              </Typography>

              {/* Disable the button when uploading */}
              <Button
                variant="contained"
                component="label"
                startIcon={<UploadFileIcon />}
                disabled={uploading} // Button is disabled while uploading
              >
                Upload Document
                <input type="file" hidden onChange={handleFileChange} />
              </Button>

              {fileError && (
                <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                  {fileError}
                </Typography>
              )}

              {uploading && (
                <Typography variant="body2" sx={{ mt: 1, color: textColor }}>
                  Uploading...
                </Typography>
              )}
            </Stack>

            {/* List of uploaded files */}
            <List sx={{ mt: 2 }}>
              {uploadedFiles.map((fileName, index) => (
                <ListItem key={index}>
                  <ListItemText primary={`Uploaded file: ${fileName}`} sx={{ color: textColor }} />
                </ListItem>
              ))}
            </List>
          </>
        )}
      </Stack>
    </Box>
  );
};

export default CertQuestionWithUpload;
