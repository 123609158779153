import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios"; // Assuming you are using axios
import { updateContact } from "../../APIHandlers/ProfileApi"; // Your API handler

// Initial state for contact data
const initialState = {
  phone: "",
  email: "",
//   address: "",
//   city: "",
//   state: "",
//   zip_code: "",
  facebook: "",
  instagram: "",
  youtube: "",
  tiktok: "",
  website: "",
  status: "", // Optional for async state management
  error: null, // Optional for error handling
};

// Async thunk to update contact information
export const updateContactAsync = createAsyncThunk(
  "Contact/updateContact",
  async ({ data, token }, { rejectWithValue }) => {
    try {
        //console.log("Contact/updateContact", data); 
      const response = await updateContact(data, token); // Call the API to update contact
      return response.data; // Return the updated contact data from the API response
    } catch (error) {
      return rejectWithValue(error.response.data); // Return the error in case of failure
    }
  }
);

const ContactSlice = createSlice({
  name: "Contact",
  initialState,
  reducers: {
    addContact(state, action) {
      localStorage.setItem("contact", JSON.stringify(action.payload)); // Save to localStorage
      return action.payload; // Update the Redux store
    },
    removeContact(state) {
      state = initialState; // Reset the state to the initial value
      localStorage.removeItem("contact"); // Remove from localStorage
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateContactAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateContactAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Update the Redux store with the updated contact info
        Object.assign(state, action.payload);
        localStorage.setItem("contact", JSON.stringify(state)); // Save updated contact to localStorage
      })
      .addCase(updateContactAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload; // Capture the error for debugging or UI display
      });
  },
});

// Export actions and reducer
export const { addContact, removeContact } = ContactSlice.actions;
export default ContactSlice.reducer;
